import $ from 'jquery'
window.$ = window.jQuery = window.jquery = $

// Ladda (loading-state buttons)
import * as Ladda from 'ladda';

// Cookify
import Cookify from 'cookify/src/index';

// Foundation
import 'foundation-sites/dist/js/plugins/foundation.core';
import 'foundation-sites/dist/js/plugins/foundation.util.imageLoader';
import 'foundation-sites/dist/js/plugins/foundation.util.keyboard';
import 'foundation-sites/dist/js/plugins/foundation.util.mediaQuery';
import 'foundation-sites/dist/js/plugins/foundation.util.motion';
import 'foundation-sites/dist/js/plugins/foundation.util.timer';
import 'foundation-sites/dist/js/plugins/foundation.util.touch';
import 'foundation-sites/dist/js/plugins/foundation.util.triggers';
import 'foundation-sites/dist/js/plugins/foundation.equalizer';
import 'foundation-sites/dist/js/plugins/foundation.offcanvas';
import 'foundation-sites/dist/js/plugins/foundation.orbit';
import 'foundation-sites/dist/js/plugins/foundation.reveal';
import 'foundation-sites/dist/js/plugins/foundation.responsiveToggle';

// Initialize application

window.App || (window.App = {});

App.loadGoogleAnalytics = function() {
  console.log('Loading Google Analytics', googleAnalyticsTag);
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', googleAnalyticsTag);
}

App.loadMixpanel = function() {
  if (typeof(mixpanel) !== 'object') {
    console.log('Loading Mixpanel', mixpanelToken);
    (function(e,b){if(!b.__SV){var a,f,i,g;window.mixpanel=b;b._i=[];b.init=function(a,e,d){function f(b,h){var a=h.split(".");2==a.length&&(b=b[a[0]],h=a[1]);b[h]=function(){b.push([h].concat(Array.prototype.slice.call(arguments,0)))}}var c=b;"undefined"!==typeof d?c=b[d]=[]:d="mixpanel";c.people=c.people||[];c.toString=function(b){var a="mixpanel";"mixpanel"!==d&&(a+="."+d);b||(a+=" (stub)");return a};c.people.toString=function(){return c.toString(1)+".people (stub)"};i="disable time_event track track_pageview track_links track_forms register register_once alias unregister identify name_tag set_config people.set people.set_once people.increment people.append people.union people.track_charge people.clear_charges people.delete_user".split(" ");
    for(g=0;g<i.length;g++)f(c,i[g]);b._i.push([a,e,d])};b.__SV=1.2;a=e.createElement("script");a.type="text/javascript";a.async=!0;a.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?MIXPANEL_CUSTOM_LIB_URL:"file:"===e.location.protocol&&"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\/\//)?"https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";f=e.getElementsByTagName("script")[0];f.parentNode.insertBefore(a,f)}})(document,window.mixpanel||[]);
    mixpanel.init(mixpanelToken);
  }
}

App.initCookies = function() {
  var cookify = new Cookify('etudio_cookie_consent', function () {
    // close modal
    $('#cookies-modal').foundation('close');
  }, function (data) {
    if (data[0].analytics) {
      App.loadGoogleAnalytics();
      App.loadMixpanel();
    }
  }, false, false, 'necessary');

  if (cookify.getDataState('analytics')) {
    App.loadGoogleAnalytics();
    App.loadMixpanel();
  }

  if (!cookify.getDataState(cookify.viewedName)) {
    $('#cookies-modal').foundation('open');
  }

  $('#cookies-button').on('click', function() {
    $('#cookies-modal').foundation('open');
  });
}

App.init = function() {
  $(document).foundation();
  Ladda.bind('.ladda');
  $("a[href^='http://']").attr('target', '_blank');
  $("a[href^='https://']").attr('target', '_blank');
  if ($('#tweets').length) {
    twttr.widgets.createTweet('1311648745071824902', document.getElementById('tweet-1'));
    twttr.widgets.createTweet('1310869007873060864', document.getElementById('tweet-2'));
    return twttr.widgets.createTweet('1310494187742257152', document.getElementById('tweet-3'));
  }
  true
};

$(document).on('turbolinks:load', function() {
  App.init();
  App.initCookies();
});
